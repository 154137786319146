// POPPINS
@font-face {
  font-family: Nanum;
  font-weight: 300;
  src: url("../fonts/Nanum/NanumSquareRound-Light.ttf");
}
@font-face {
  font-family: Nanum;
  font-weight: 400;
  src: url("../fonts/Nanum/NanumSquareRound-Black.ttf");
}
@font-face {
  font-family: Nanum;
  font-weight: 500;
  src: url("../fonts/Nanum/NanumSquareRound-Black.ttf");
}
@font-face {
  font-family: Nanum;
  font-weight: 600;
  src: url("../fonts/Nanum/NanumSquareRound-Bold.ttf");
}
@font-face {
  font-family: Nanum;
  font-weight: 700;
  src: url("../fonts/Nanum/NanumSquareRound-ExtraBold.ttf");
}

/*  */
:root {
  --font-display: Nanum;
  --font-body: Nanum;
}
